.btn-brown-red {
    @apply hover:bg-[#331005] bg-[#67240e] px-6 py-4;
}

.btn-light-brown-red {
    @apply hover:bg-[#77331d] bg-[#ab4827] px-6 py-4;
}

.simple-input {
    @apply focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] px-4 py-2 placeholder-[#67240e];
}
/* custom-scrollbar.css */
.custom-scrollbar {
    overflow-y: auto; /* Habilita o scroll vertical */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 12px; /* Largura do scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: linear-gradient(to bottom, #f1f1f1 0%, #ffffff 100%); /* Cor do fundo do track com gradiente */
    border-radius: 0 15px 135px 0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #b34a27; /* Cor do thumb */
    border-radius: 15px; /* Arredondar todas as bordas do thumb */
}

/* Personalização para o hover do thumb */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #7a2c12; /* Cor do thumb ao passar o mouse */
}

/* Usando pseudo-elementos para criar um arredondamento específico */
.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 0 15px 135px 0; /* Arredondar a borda inferior direita */
}
