body {
    font-family: Arial, sans-serif;
    color: #ffffff;

    background-size: cover;
    background-position: center;
    margin: 0;
    padding: 0;
}
.content {
    max-width: 800px;
    margin: auto;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
}
h1 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 10px;
}
h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
}
p, ul {
    font-size: 1.1em;
    line-height: 1.6em;
}
ul {
    list-style-type: none;
    padding-left: 0;
}
ul li {
    margin-bottom: 15px;
}
ul li:before {
    content: "🔹";
    padding-right: 5px;
}
a {
    color: #ffd700;
    font-weight: bold;
    text-decoration: none;
}