/* Estilo global */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-image: url('../../public/img/novoservidor/imagens/bg.jpg');
    background-attachment: fixed;  /* Cria o efeito parallax */
    background-size: cover;
    background-position: center;
    color: #ffffff;
}
*, *::before, *::after {
    box-sizing: border-box;
}

/* Estilo do cabeçalho */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
}

.minilogo {
    height: 50px;
}

/* Estilo para o menu desktop */
.desktop-menu {
    display: flex;
    gap: 30px;
}

.desktop-menu a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}
/* Estilo para ícones sociais */
.social-icons img {
    height: 15px;
}

/* Estilo para ícones do menu (ico1, ico2, ico3) */
img[src*="ico1.png"], img[src*="ico2.png"], img[src*="ico3.png"] {
    height: 25px;
    width: 25px;
    object-fit: contain; /* Garante que a imagem se ajuste ao tamanho especificado sem distorção */
    position: relative;
    top: 7px; /* Ajusta a altura em relação ao topo da página */
}


/* Estilo para ícones sociais */
.social-icons {
    display: flex;
    gap: 15px;
}

.social-icons img {
    height: 25px;
}

/* Estilo para o menu mobile */
.mobile-menu {
    display: none;
    font-size: 26px;
    background: none;
    border: none;
    color: white;
}

/* Menu links do mobile */
#mobile-menu-links {
    display: none;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 15px;
    text-align: center;
}

#mobile-menu-links a {
    display: block;
    text-decoration: none;
    color: #d1b7ff;
    margin-bottom: 12px;
}

/* Estilo da logo principal */
.logo {
    display: block;
    margin: 10px auto;
    max-width: 20% !important;;
    height: auto;
}

/* Quadro de cadastro */
.registration-box {
    background: linear-gradient(135deg, rgb(39 17 53 / 80%), rgb(86 0 157 / 70%));
    padding: 30px;
    border-radius: 15px;
    width: 80%;
    max-width: 1200px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    text-align: center;
}

.registration-header h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
}

.registration-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

.registration-image {
    max-width: 250px;
    margin-right: 0px;
    border-radius: 10px;
    box-shadow: 0 px 8px rgba(0, 0, 0, 0.3);
}

.registration-box form {
    width: 100%;
}

.form-group {
    margin-bottom: 20px;
    position: relative;
}

.registration-box input {
    width: calc(80% - 10px);
    padding: 15px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
    background: #222;
    color: #fff;
    outline: none;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.5);
}

/* Estilo específico para dispositivos móveis */
@media (max-width: 768px) {
    .registration-box input {
        width: calc(120% - 10px); /* Aumentando a largura para dispositivos móveis */
    }
}


.registration-box input:focus {
    border: 2px solid #ff6600; /* Destaque para quando o campo está em foco */
}

.error-message {
    color: #ff4c4c;
    font-size: 0.9rem;
    position: absolute;
    top: 60px;
    left: 0;
}

/* Botão de envio */
#submit-button {
    background-color: #AB10A1;
    color: #ffffff;
    font-weight: bold;
    border: none;
    padding: 15px;
    border-radius: 10px;
    width: 60%; /* Ajuste feito para desktop */
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

/* Responsividade - Media Queries */
/* Correção para inputs no mobile */
@media (max-width: 768px) {
    .registration-box input {
        width: 110%; /* Mantém os campos dentro do quadro */
        max-width: 95%; /* Evita que fiquem muito largos */
        padding: 12px; /* Ajuste no padding para melhor estética */
    }

    /* Correção para o botão no mobile */
    #submit-button {
        width: 100%; /* Garante que fique alinhado ao container */
        max-width: 80%; /* Mantém um tamanho adequado */
        padding: 12px; /* Ajuste de padding para um visual mais equilibrado */
        font-size: 1rem; /* Reduz a fonte para melhor encaixe */
    }

    /* Ajustando o alinhamento dos elementos dentro do quadro */
    .registration-box {
        align-items: center; /* Garante que os elementos fiquem centralizados */
        text-align: center;
    }
}



#submit-button:hover {
    background-color: #51024c;
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

#submit-button:active {
    transform: scale(0.98);
}

/* Estilo do rodapé */
footer {
    text-align: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    position: relative;
    bottom: 0;
    width: 100%;
}

/* Estilo do quadro do timer */
.countdown-container {
    text-align: center;
    width: 50%;
    margin: 30px auto;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Estilo do título do contador */
.countdown-title {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.8),
    -1px -1px 2px rgba(255, 255, 255, 0.4);
    letter-spacing: 2px;
}

/* Estilo dos números do contador */
#countdown-timer {
    font-family: 'Arial Black', Arial, sans-serif;
    color: #ffffff;
    font-size: 3rem;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

/* Estilo específico para os segundos */
#countdown-timer .seconds {
    color: #AB10A1;
}

/* Responsividade - Media Queries */
@media (max-width: 768px) {
    .invitation-text {
        display: block; /* Torna visível apenas no mobile */
        text-align: center;
        padding: 15px 10px;
    }

    .invitation-text h2 {
        font-size: 1.4rem; /* Diminui o tamanho da fonte */
        font-weight: bold;
        margin: 10px 20px;
        line-height: 1.4;
    }

    .registration-box {
        margin-top: 0px; /* Remove espaçamento extra no mobile */
    }
}

/* 🔹 NO DESKTOP: OCULTA O BLOCO DE CONVITE PARA NÃO BAGUNÇAR */
@media (min-width: 769px) {
    .invitation-text {
        display: none; /* Esconde o bloco no desktop */
    }
}
    .desktop-menu {
        display: none;
    }

    .mobile-menu {
        display: block;
    }

    .logo {
        max-width: 60%;
    }

    .registration-box {
        flex-direction: column;
        align-items: center;
    }

    /* Ajuste a ordem dos elementos no mobile */
    .registration-content {
        flex-direction: column;
        align-items: center;
    }

    .registration-image {
        max-width: 150px;
        margin-bottom: 0px;
        order: 1; /* Imagem no topo */
    }

    .registration-header {
        order: 2; /* Mensagem abaixo da imagem */
        margin-bottom: 0px;
    }

    .registration-content form {
        order: 3; /* Formulário abaixo da mensagem */
        width: 100%;
        margin: 20px auto;
        padding: 0px;
    }

    .countdown-container {
        width: 80%;
        margin: 20px auto;
    }

    #countdown-timer {
        font-size: 2.0rem;
    }

/* Caixa de Confirmação de Cadastro */
.confirmation-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    width: 80%;
    max-width: 400px;
}

.confirmation-box h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
}

.confirmation-box p {
    font-size: 1.1em;
    margin-bottom: 20px;
}

/* Estilo principal do botão */
.whatsapp-button {
    display: inline-block; /* Garante que o botão seja tratado como elemento em linha-bloco */
    background-color: #28a745; /* Cor principal do botão */
    color: #ffffff; /* Cor do texto */
    padding: 12px 20px; /* Ajuste para um espaçamento confortável */
    text-decoration: none; /* Remove o sublinhado */
    font-size: 1rem; /* Tamanho de fonte padrão */
    font-weight: bold; /* Texto em negrito */
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para destacar o botão */
    text-align: center; /* Centraliza o texto */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Animação ao passar o mouse */
}

/* Efeito hover */
.whatsapp-button:hover {
    background-color: #218838; /* Cor ao passar o mouse */
    transform: scale(1.05); /* Leve aumento ao passar o mouse */
}

/* Regras de responsividade */
@media (max-width: 768px) {
    .whatsapp-button {
        font-size: 0.9rem; /* Reduz o tamanho da fonte em telas menores */
        padding: 10px 15px; /* Ajusta o espaçamento para dispositivos móveis */
        width: 100%; /* Faz o botão ocupar 100% da largura */
        box-sizing: border-box; /* Inclui bordas e padding no cálculo da largura */
    }

    /* Certifique-se de centralizar o botão no contêiner */
    .confirmation-actions {
        text-align: center; /* Centraliza o botão */
    }
}
.security-badges {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
}

.security-icon {
    width: 40px;
    height: auto;
    margin-right: 8px;
}
.small-text {
    font-size: 12px;
    color: #bbbbbb;
    text-align: center;
    margin-top: 5px;
}

